<template>
  <!-- eslint-disable vue/no-multiple-template-root -->
  <div v-if="!countdownDone && privateShowCountdown" class="countdown-container">
    <div class="time-container">
      <Transition name="countdown-trans">
        <div class="time-big" v-if="timeLeft.day % 2 == 0">{{ padNumber(timeLeft.day) }}</div>
        <div class="time-big" v-else>{{ padNumber(timeLeft.day) }}</div>
      </Transition>
      <div>Hari</div>
    </div>
    <div class="time-container">
      <div>:</div>
      <div style="color: transparent">s</div>
    </div>

    <div class="time-container">
      <Transition name="countdown-trans">
        <div class="time-big" v-if="timeLeft.hour % 2 == 0">{{ padNumber(timeLeft.hour) }}</div>
        <div class="time-big" v-else>{{ padNumber(timeLeft.hour) }}</div>
      </Transition>
      <div>Jam</div>
    </div>
    <div class="time-container">
      <div>:</div>
      <div style="color: transparent">s</div>
    </div>

    <div class="time-container">
      <Transition name="countdown-trans">
        <div class="time-big" v-if="timeLeft.minute % 2 == 0">{{ padNumber(timeLeft.minute) }}</div>
        <div class="time-big" v-else>{{ padNumber(timeLeft.minute) }}</div>
      </Transition>
      <div>Minit</div>
    </div>
    <div class="time-container">
      <div>:</div>
      <div style="color: transparent">s</div>
    </div>

    <div class="time-container">
      <Transition name="countdown-trans">
        <div class="time-big" v-if="timeLeft.seconds % 2 == 0">{{ padNumber(timeLeft.seconds) }}</div>
        <div class="time-big" v-else>{{ padNumber(timeLeft.seconds) }}</div>
      </Transition>
      <div>Saat</div>
    </div>
  </div>
  <slot v-else-if="countdownDone"></slot>
</template>

<script setup>
import { defineProps, onMounted, ref, watch, defineEmits } from 'vue';

const props = defineProps({
  when: Date,
  showCountdown: Boolean,
  done: Boolean,
});

const emit = defineEmits(['update:done']);

const privateWhen = ref(null);
const privateShowCountdown = ref(true);
const defaultDayToAdd = ref(5);
const countdownDone = ref(false);
const timeLeft = ref({
  day: 0,
  hour: 0,
  minute: 0,
  seconds: 0,
});

onMounted(() => {
  if (props.when) {
    privateWhen.value = props.when;
  } else {
    privateWhen.value = new Date(
      new Date().getTime() + defaultDayToAdd.value * 24 * 60 * 60 * 1000
    );
  }

  if (props.showCountdown == undefined || props.showCountdown == null) {
    privateShowCountdown.value = true;
  } else {
    privateShowCountdown.value = props.showCountdown;
  }

  calculateCountDown();

  let delay = new Date().getMilliseconds();
  setTimeout(() => {
    timeChanged();
  }, delay);
});

const calculateCountDown = () => {
  if (privateWhen.value) {
    let millis = privateWhen.value.getTime() - new Date().getTime();
    if (millis < 0) {
      // countdownDone.value = true;
      emit('update:done', true);
    }
    let seconds = Math.floor(millis / 1000);
    let minutes = Math.floor(seconds / 60);
    let hours = Math.floor(minutes / 60);
    let days = Math.floor(hours / 24);

    timeLeft.value.day = days < 0 ? 0 : days;
    timeLeft.value.hour = hours % 24 < 0 ? 0 : hours % 24;
    timeLeft.value.minute = minutes % 60 < 0 ? 0 : minutes % 60;
    timeLeft.value.seconds = seconds % 60 < 0 ? 0 : seconds % 60;
  } else {
    timeLeft.value.day = 0;
    timeLeft.value.hour = 0;
    timeLeft.value.minute = 0;
    timeLeft.value.seconds = 0;
  }
};

const timeChanged = () => {
  let nextSecond = new Date(new Date().setSeconds(new Date().getSeconds() + 1));
  nextSecond = new Date(nextSecond.setMilliseconds(0));

  let delay = nextSecond.getTime() - new Date().getTime();
  let isPast = nextSecond.getTime() >= privateWhen.value.getTime() + 2000;
  if (isPast) {
    // countdownDone.value = true;
    return;
  }
  setTimeout(() => {
    calculateCountDown();
    timeChanged();
  }, delay);
};

const padNumber = (num) => {
  if (num >= 10) {
    return num;
  } else {
    return `0${num}`;
  }
};

watch(() => props.when, (newVal) => {
  privateWhen.value = new Date(newVal);
  calculateCountDown();
});
</script>

<style scoped>
.countdown-container {
  display: flex;
  align-items: center;
  justify-content: center;
}
.time-container {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 0 3px;
  position: relative;
  overflow: hidden;
}
.time-big {
  position: relative;
  top: 0;
  height: 100%;
  width: 100%;
  font-size: 2em;
}
.time-big:nth-child(2) {
  position: absolute;
}

.countdown-trans-enter-active,
.countdown-trans-leave-active {
  transition: 0.3s;
}
.countdown-trans-enter-from {
  opacity: 0;
  transform: translateY(100%);
}
.countdown-trans-leave-to {
  transform: translateY(-100%);
}
</style>