<template>
  <div class="word-swipe-main" :style="{ justifyContent: getTextAlign() }" ref="wordSwipeMain">
    <div v-if="text" :style="{ justifyContent: getTextAlign(), opacity: playState == 'paused' ? '0' : '1' }">
      <div class="swiper" :style="{ animationDuration: `${getSpeed()}s`, backgroundColor: swiperColor || 'white', animationPlayState: playState }"></div>
      <div>{{ text }}</div>
    </div>
    <div v-else :style="{ justifyContent: getTextAlign() }">
      Text is not set
    </div>
  </div>
</template>

<script setup>
import { defineProps, ref, onMounted, watch } from 'vue';

const wordSwipeMain = ref();
const playState = ref('paused');

const props = defineProps({
  text: String,
  textAlign: String,
  speed: Number,
  swiperColor: String,
  scrollPosition: Number,
});

const getTextAlign = () => {
  if (props.textAlign) {
    switch (props.textAlign) {
      case 'left':
        return 'flex-start';
      case 'center':
        return 'center';
      case 'right':
        return 'flex-end';
      default:
        return 'center';
    }
  } else {
    return 'center';
  }
};

const getSpeed = () => {
  if (!props.speed) {
    return 5;
  } else {
    if (props.speed > 20) {
      return 20;
    } else if (props.speed < 2) {
      return 2;
    } else {
      return props.speed;
    }
  }
};

const checkIfShow = () => {
  let pageHeight = window.innerHeight;
  let mainRect = wordSwipeMain.value.getBoundingClientRect();

  let elTop = mainRect.top;
  let elBottom = mainRect.bottom;
  if ((elTop > 50 && elTop < pageHeight - 50) && (elBottom > 50 && elBottom < pageHeight - 100) && playState.value == 'paused') {
    playState.value = 'running';
  }
};

watch(() => props.scrollPosition, () => {
    checkIfShow();
  }
);

onMounted(() => {
  setTimeout(() => {
    checkIfShow();
  }, 3000);
});
</script>

<style scoped>
.word-swipe-main {
  display: flex;
}
.word-swipe-main > :nth-child(1) {
  white-space: nowrap;
  overflow: hidden;
  position: relative;
}
.swiper {
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 50%;
  animation-name: swipe;
  animation-fill-mode: forwards;
  animation-timing-function: ease-out;
}
@keyframes swipe {
  from {
    width: 100%;
    left: 0%;
  }
  to {
    width: 0%;
    left: 100%;
  }
}
</style>