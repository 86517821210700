<template>
  <div class="slider-main" :style="{ opacity: isDisabled ? '0.5' : '1' }">
    <div>Bilangan Kehadiran</div>
    <div class="count-section">
      <div @click="decrese">-</div>
      <div>{{ myValue }} orang</div>
      <div @click="increase">+</div>
    </div>
  </div>
</template>

<script setup>
import { defineProps, defineEmits, onMounted, ref, watch } from 'vue';

const props = defineProps({
  value: Number,
  min: Number,
  max: Number,
  isDisabled: Boolean
});

const emit = defineEmits([
  'update:value'
]);

const myValue = ref(2);
const myMin = ref(1);
const myMax = ref(10)

onMounted(() => {
  if (props.value != undefined && props.value != null) {
    myValue.value = props.value;
  }
  if (props.min != undefined && props.min != null) {
    myMin.value = props.min;
  }
  if (props.max != undefined && props.max != null) {
    myMax.value = props.max;
  }
})

const decrese = () => {
  if (props.isDisabled) {
    return;
  }

  myValue.value--;
  if (myValue.value < myMin.value) {
    myValue.value = myMin.value;
  }

  emit('update:value', myValue.value);
}

const increase = () => {
  if (props.isDisabled) {
    return;
  }
  
  myValue.value++;
  if (myValue.value > myMax.value) {
    myValue.value = myMax.value;
  }

  emit('update:value', myValue.value);
}

watch(() => props.value, (newVal) => {
  myValue.value = newVal;
})
</script>

<style scoped>
.slider-main {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  color: rgb(109,58,9);
}
.count-section {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 5px;
}
.count-section > * {
  margin: 0 5px;
}
.count-section > :nth-child(1), .count-section > :nth-child(3) {
  border: 1px solid gray;
  background-color: rgb(234,153,78);
  width: 30px;
  min-width: 30px;
  max-width: 30px;
  height: 30px;
  min-height: 30px;
  max-height: 30px;
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: 0.3s;
  box-shadow: 2px 2px 2px gray;
}
</style>