<template>
  <div class="typewriter-main" ref="typewriterMain">
    <div v-if="text" :style="{ justifyContent: getTextAlign() }">
      <div class="typewriter-text" v-for="(t, i) in text.split('')" :key="i"
        :style="{ animationDelay: `${getSpeed(i)}s`, animationPlayState: playState }">
        {{ t == ' ' ? '&nbsp;' : t }}
      </div>
    </div>
    <div v-else :style="{ justifyContent: getTextAlign() }">
      Text is not set
    </div>
  </div>
</template>

<script setup>
import { defineProps, ref, onMounted, watch } from 'vue';

const typewriterMain = ref();
const playState = ref('paused');

const props = defineProps({
  text: String,
  textAlign: String,
  speed: Number,
  scrollPosition: Number,
});

const getTextAlign = () => {
  if (props.textAlign) {
    switch (props.textAlign) {
      case 'left':
        return 'flex-start';
      case 'center':
        return 'center';
      case 'right':
        return 'flex-end';
      default:
        return 'center';
    }
  } else {
    return 'center';
  }
};

const getSpeed = (ind) => {
  if (!props.speed) {
    return ind * 0.1;
  } else {
    if (props.speed > 1) {
      return ind * 1;
    } else if (props.speed < 0.05) {
      return ind * 0.05;
    } else {
      return ind * props.speed;
    }
  }
};

const checkIfShow = () => {
  let pageHeight = window.innerHeight;
  let mainRect = typewriterMain.value.getBoundingClientRect();

  let elTop = mainRect.top;
  let elBottom = mainRect.bottom;
  if ((elTop > 50 && elTop < pageHeight - 50) && (elBottom > 50 && elBottom < pageHeight - 100) && playState.value == 'paused') {
    playState.value = 'running';
  }
};

watch(() => props.scrollPosition, () => {
    checkIfShow();
  }
);

onMounted(() => {
  setTimeout(() => {
    checkIfShow();
  }, 3000);
});
</script>

<style scoped>
.typewriter-main > :nth-child(1) {
  display: flex;
}
.typewriter-text {
  opacity: 0;
  animation: typing 0.01s linear forwards;
}
@keyframes typing {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
</style>