<template>
  <div class="wish-photo-main">
    <img src="@/assets/images/PhotoAttachment.png">
    <div v-if="!photo" class="photo-name">Tiada Gambar</div>
    <div v-else class="photo-name">{{ photo.name }}</div>
    <input type="file" ref="inputFile" @change="fileChanged" accept="image/*">
    <img v-if="photo" class="delete-photo" src="@/assets/images/Close.png" @click="deleteClicked">
  </div>
</template>

<script setup>
import { ref, defineProps, defineEmits, watch } from 'vue';

const inputFile = ref();
const photo = ref(null);

const props = defineProps({
  file: Object
});
const emit = defineEmits(['update:file']);

const fileChanged = (e) => {
  let images = Array.from(e.target.files);
  if (images.length > 0) {
    photo.value = images[0];
  } else {
    photo.value = null;
  }
  emit('update:file', photo.value);
}

const deleteClicked = () => {
  inputFile.value.value = '';
  photo.value = null;
  emit('update:file', photo.value);
}

watch(() => props.file, (newVal) => {
  if (newVal == null) {
    photo.value = null;
  }
})
</script>

<style scoped>
.wish-photo-main {
  display: flex;
  align-items: center;
  width: 100%;
  position: relative;
  font-size: 0.8em;
}
.wish-photo-main > img {
  height: 30px;
  width: 30px;
  object-fit: contain;
  margin-right: 10px;
}
.wish-photo-main > input {
  position: absolute;
  z-index: 5;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
}
.photo-name {
  white-space: pre-wrap;
  word-break: break-all;
  padding-right: 25px;
}
.delete-photo {
  z-index: 6;
  position: absolute;
  margin: 0 !important;
  right: 0px;
  height: 20px !important;
  width: 20px !important;
  object-fit: contain;
}
</style>