<template>
  <div class="letter-pop-main" ref="letterPopMain">
    <div v-if="text" :style="{ justifyContent: getTextAlign() }">
      <div class="letter-pop-text" v-for="(t, i) in text.split('')" :key="i" :style="{ animationDelay: `${getSpeed(i)}s`, animationPlayState: playState }">
        {{ t == ' ' ? '&nbsp;' : t }}
      </div>
    </div>
    <div v-else :style="{ justifyContent: getTextAlign() }">
      Text is not set
    </div>
  </div>
</template>

<script setup>
import { defineProps, ref, onMounted, watch } from 'vue';

const letterPopMain = ref();
const playState = ref('paused');

const props = defineProps({
  text: String,
  textAlign: String,
  speed: Number,
  scrollPosition: Number,
});

const getTextAlign = () => {
  if (props.textAlign) {
    switch (props.textAlign) {
      case 'left':
        return 'flex-start';
      case 'center':
        return 'center';
      case 'right':
        return 'flex-end';
      default:
        return 'center';
    }
  } else {
    return 'center';
  }
};

const getSpeed = (ind) => {
  if (!props.speed) {
    return ind * 0.1;
  } else {
    if (props.speed > 1) {
      return ind * 1;
    } else if (props.speed < 0.05) {
      return ind * 0.05;
    } else {
      return ind * props.speed;
    }
  }
};

const checkIfShow = () => {
  let pageHeight = window.innerHeight;
  let mainRect = letterPopMain.value.getBoundingClientRect();

  let elTop = mainRect.top;
  let elBottom = mainRect.bottom;
  if ((elTop > 50 && elTop < pageHeight - 50) && (elBottom > 50 && elBottom < pageHeight - 100) && playState.value == 'paused') {
    playState.value = 'running';
  }
};

watch(() => props.scrollPosition, () => {
    checkIfShow();
  }
);

onMounted(() => {
  setTimeout(() => {
    checkIfShow();
  }, 3000);
});
</script>

<style scoped>
.letter-pop-main > :nth-child(1) {
  display: flex;
}
.letter-pop-text {
  transform: scale(0);
  animation: letterShow 0.5s ease forwards;
}
@keyframes letterShow {
  0% {
    transform: scale(0) translate(0, 0);
  }
  50% {
    transform: scale(1.2) translate(5px, -5px);
  }
  100% {
    transform: scale(1) translate(0, 0);
  }
}
</style>