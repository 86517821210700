<template>
  <!-- eslint-disable vue/no-v-model-argument -->
  <div class="home-main">
    <div class="content">
      <Typewriter class="walimatul droid-serif" text="MAJLIS PERKAHWINAN" :scrollPosition="scrollTop" />

      <div class="main-name main-rr">
        <img src="@/assets/images/RR Full.png" alt="">
      </div>
      <WordSwipe class="madelyn" style="font-size: 3.5em" text="Reena & Rafiq" :scrollPosition="scrollTop" />

      <div class="date-container">
        <div class="date-row">DEC</div>
        <div class="date-row">
          <div>AHAD</div>
          <div>24</div>
          <div>11 AM</div>
        </div>
        <div class="date-row">2023</div>
      </div>

      <div class="ibarra-real-nova" style="margin-top: 1.5em">BIZMILLA GRAND BALLROOM</div>
      <div class="ibarra-real-nova">ECO SANCTUARY</div>

      <div class="yeseva-one" style="font-size: 0.65em; margin-top: 2em">"dan kami ciptakan kamu berpasang-pasangan"</div>
      <div class="yeseva-one" style="font-size: 0.65em">Surah An-Naba` (78:8)</div>

      <img class="bismillah" src="@/assets/images/Bismillah.png" alt="">

      <div class="ibarra-real-nova" style="margin-top: 1em">Assalamualaikum & Salam Sejahtera</div>

      <div class="ibarra-real-nova" style="margin-top: 1em; font-size: 1.2em; font-weight: bold">MAHMUZI BIN OSMAN</div>
      <div class="buffalo" style="font-size: 1.5em">serta</div>
      <div class="ibarra-real-nova" style="font-size: 1.2em; font-weight: bold">DURINA BINTI ABDULLAH</div>
      <div class="ibarra-real-nova">&</div>
      <div class="ibarra-real-nova" style="font-size: 1.2em; font-weight: bold">CHE ASIAH BINTI GAZALI</div>

      <div class="ibarra-real-nova" style="margin-top: 2em">Dengan besar hati ingin menjemput<br>Tuan / Puan / Encik / Cik</div>
      <div class="ibarra-real-nova">Ke majlis perkahwinan puteri bongsu kami</div>

      <LetterPop class="citadel" style="margin-top: 0.5em; font-size: 2em" text="Nur Effreena Ezzaty" :scrollPosition="scrollTop" />
      <Typewriter class="ibarra-real-nova" style="margin-top: 5px; font-weight: 700" text="BINTI MAHMUZI" :scrollPosition="scrollTop" />
      <div class="buffalo" style="margin-top: 5px; font-size: 1.5em">bersama pasangannya</div>
      <LetterPop class="citadel" style="margin-top: 5px; font-size: 2em" text="Muhamad Rafiq Akmal" :scrollPosition="scrollTop" />
      <Typewriter class="ibarra-real-nova" style="margin-top: 5px; font-weight: 700" text="BIN HARON" :scrollPosition="scrollTop" />
      <div class="buffalo" style="margin-top: 5px; font-size: 1.5em">pada</div>
      <div class="ibarra-real-nova" style="margin-top: 5px; font-size: 1.5em">AHAD, 24 DEC 2023</div>
      <div class="ibarra-real-nova" style="margin-top: 5px">Bersamaan 11 Jamadilakhir 1445H</div>

      <div class="buffalo" style="margin-top: 5px; font-size: 1.5em">bertempat di</div>
      <div class="ibarra-real-nova" style="margin-top: 5px; font-weight: bold">BIZMILLA GRAND BALLROOM</div>
      <div class="ibarra-real-nova" style="font-weight: bold">ECO SANCTUARY</div>
      <div class="ibarra-real-nova" style="margin-top: 10px; font-size: 0.7em">LEVEL 2,</div>
      <div class="ibarra-real-nova" style="font-size: 0.7em">SANCTUARY MALL,</div>
      <div class="ibarra-real-nova" style="font-size: 0.7em">JALAN ECO SANTUARI 8/3 ECO SANTUARI,</div>
      <div class="ibarra-real-nova" style="font-size: 0.7em">42500, TELOK PANGLIMA GARANG</div>
      <div class="ibarra-real-nova" style="font-size: 0.7em">SELANGOR D.E</div>
      
      <div class="maps">
        <a href="https://www.waze.com/ul?ll=2.944746515347039%2C101.54308130942138&navigate=yes" target="_blank">
          <img src="@/assets/images/Waze.png" alt="" @click="mapClicked('waze')">
        </a>
        <a href="https://maps.google.com/?q=2.944746515347039,101.54308130942138" target="_blank">
          <img src="@/assets/images/GoogleMaps.png" alt="" @click="mapClicked('gmap')">
        </a>
      </div>

      <Typewriter class="droid-serif-bold" style="margin-top: 2em; font-size: 1.5em" text="Aturcara Majlis" :scrollPosition="scrollTop" />
      <table class="ibarra-real-nova timing-table">
        <tr>
          <td>11.00 am</td>
          <td class="vertical-line"></td>
          <td></td>
        </tr>
        <tr>
          <td>4.00 pm</td>
          <td class="vertical-line"><div class="horizontal-line"></div></td>
          <td>Jamuan Makan</td>
        </tr>
        <tr>
          <td>12.30 pm</td>
          <td class="vertical-line"><div class="horizontal-line"></div></td>
          <td>Ketibaan Pengantin</td>
        </tr>
        <tr>
          <td>3.00 pm</td>
          <td class="vertical-line"><div class="horizontal-line"></div></td>
          <td>Sesi Bergambar</td>
        </tr>
        <tr>
          <td>4.00 pm</td>
          <td class="vertical-line"><div class="horizontal-line"></div></td>
          <td>Majlis Bersurai</td>
        </tr>
      </table>

      <Typewriter class="droid-serif-bold" style="margin-top: 2em; font-size: 1.5em" text="Hubungi" :scrollPosition="scrollTop" />
      <table class="ibarra-real-nova timing-table">
        <tr>
          <td>Ezreen</td>
          <td class="vertical-line"><div class="horizontal-line"></div></td>
          <td><a href="tel:+60176160554">017 - 616 0554</a></td>
        </tr>
        <tr>
          <td>Shaiful</td>
          <td class="vertical-line"><div class="horizontal-line"></div></td>
          <td><a href="tel:+601110100554">011 - 1010 0554</a></td>
        </tr>
        <tr>
          <td>Ernie</td>
          <td class="vertical-line"><div class="horizontal-line"></div></td>
          <td><a href="tel:+601110310254">011 - 1031 0254</a></td>
        </tr>
        <tr>
          <td>Asiah</td>
          <td class="vertical-line"><div class="horizontal-line"></div></td>
          <td><a href="tel:+60123467755">012 - 346 7755</a></td>
        </tr>
        <tr>
          <td>Muzie</td>
          <td class="vertical-line"><div class="horizontal-line"></div></td>
          <td><a href="tel:+60192171182">019 - 217 1182</a></td>
        </tr>
      </table>

      <div class="buffalo" style="font-size: 1.5em; margin-top: 2em">Semoga dengan kehadiran Tuan/Puan sekalian akan menyerikan lagi majlis kami serta diberkati oleh Allah S.W.T.</div>

      <div class="buffalo" style="margin-top: 1em; font-size: 2em">#RaFoReen</div>


      <Countdown :showCountdown="true" :when="majlisStartDate" v-model:done="majlisStarted" class="ibarra-real-nova" style="font-size: 1.2em; margin-top: 2em" />
      <Countdown :when="majlisEndDate" v-model:done="majlisEnded" class="ibarra-real-nova" />
      <div class="ibarra-real-nova" v-if="majlisStarted && !majlisEnded" style="margin-top: 2em">Majlis Sedang Berlangsung</div>
      <div class="ibarra-real-nova" v-if="majlisStarted && majlisEnded" style="margin-top: 2em">Majlis Telah Tamat<br>Terima Kasih<br>Kepada Semua Yang Hadir</div>

      <div class="button-section">
        <button @click="rsvpClicked">
          <img src="@/assets/images/rsvp.png" alt="">
          <div>RSVP</div>
        </button>
        <button @click="wishesClicked">
          <img src="@/assets/images/Wishes.png" alt="">
          <div>Ucapan</div>
        </button>
      </div>
    </div>

    <Transition name="rsvptrans">
      <div v-if="showRSVP" class="overlay-page" style="color: rgb(109,58,9)">
        <button class="close-rsvp droid-serif" @click="showRSVP = false">
          <img src="@/assets/images/Close.png" alt="">
          <div>Tutup</div>
        </button>
        <div class="droid-serif" v-if="rsvpLoading">Menghantar RSVP</div>
        <div class="droid-serif" v-else>Letak nama anda untuk RSVP</div>
        <input class="droid-serif" style="margin-top: 5px; color: rgb(109,58,9)" type="text" placeholder="Nama" v-model="rsvpName" @input="uppercaseRSVPName" :disabled="rsvpNameDisabled"
          @focus="RSVPError = ''">
        <input class="droid-serif" style="margin-top: 5px; color: rgb(109,58,9)" type="tel" placeholder="No Tel" v-model="rsvpPhone" :disabled="rsvpNameDisabled"
          @focus="RSVPError = ''">
        <Slider class="droid-serif" v-model:value="rsvpHeadCount" :isDisabled="rsvpNameDisabled" />
        <div v-if="RSVPError" class="error droid-serif" style="margin-top: 5px">{{ RSVPError }}</div>
        <div v-if="RSVPSuccess" class="success droid-serif" style="margin-top: 5px">{{ RSVPSuccess }}</div>
        <LoadingButton class="droid-serif" @clicked="rsvpSubmit" :isLoading="rsvpLoading" :isDisabled="rsvpNameDisabled" style="margin-top: 0.5em">
          <div>RSVP</div>
        </LoadingButton>
      </div>
    </Transition>

    <Transition name="wishtrans" style="z-index: 12">
      <div v-if="showAddWish" class="overlay-page wish-page droid-serif">
        <button class="close-rsvp" @click="showAddWish = false">
          <img src="@/assets/images/Close.png" alt="">
          <div>Tutup</div>
        </button>
        <!-- <div class="droid-serif">Tulisan</div>
        <Dropdown :items="fontListing" mykey="name" value="value" v-model:selected="selectedFont" /> -->
        <div class="droid-serif">Ucapan</div>
        <textarea :style="{ fontFamily: selectedFont }" v-model="wishMessage" @focus="wishError = ''"></textarea>
        <div class="droid-serif">Dari</div>
        <input :style="{ fontFamily: selectedFont }" v-model="wishSender" @focus="wishError = ''">
        <div class="droid-serif">Letak Gambar</div>
        <WishPhotoInput v-model:file="wishPhoto" />
        <div style="display: flex; flex-direction: column; align-items: center; width: 100%; margin-top: 10px">
          <div v-if="wishError" class="error droid-serif" style="margin-bottom: 5px">{{ wishError }}</div>
          <div v-if="wishSuccess" class="success droid-serif" style="margin-bottom: 5px">{{ wishSuccess }}</div>
          <LoadingButton @clicked="submitWish" :isLoading="submittingWish">
            <div>Hantar Ucapan</div>
          </LoadingButton>
        </div>
      </div>
    </Transition>

    <Transition name="wishtrans">
      <div v-if="showWishes" class="overlay-page wish-page droid-serif">
        <button class="close-rsvp" @click="showWishes = false">
          <img src="@/assets/images/Close.png" alt="">
          <div>Tutup</div>
        </button>
        <div style="width: 100%; display: flex; justify-content: center">
          <button class="main-button" @click="showAddWish = true">Tambah Ucapan</button>
        </div>
        <div class="all-wishes-container">
          <div v-if="loadingWishes" class="loader"></div>
          <div v-else-if="!wishListing">Tiada Ucapan</div>
          <div v-else-if="wishListing.length == 0">Tiada Ucapan</div>
          <div v-for="(w,i) in wishListing" :key="i" class="wish-container">
            <div :style="{ fontFamily: w.font }">{{ w.message }}</div>
            <img v-show="w.photoid" :src="`/Wishes/GetPhoto?id=${w.id}&thumb=true`" @click="openPhoto(w.id)">
            <div :style="{ fontFamily: w.font }" @click="deleteWishClicked(w)">{{ w.sender }}</div>
            <div v-show="w.deleteWish">
              <div @click="wishDeleteBackgroundClicked(w)"></div>
              <div v-if="w.deletingWish" class="loader" style="max-height: 20px; max-width: 20px"></div>
              <img v-else src="@/assets/images/Delete.png" @click="wishConfirmDelete(w)">
            </div>
          </div>
        </div>
      </div>
    </Transition>
  </div>
</template>

<script setup>
/* eslint-disable */
import { ref, onMounted, watch } from 'vue';
import { api, simulateAPI } from '../js/apihelper';
import { useStore } from 'vuex';
import Dropdown from '../components/Dropdown.vue';
import LoadingButton from '../components/LoadingButton.vue';
import Typewriter from '../components/Typewriter.vue';
import WordSwipe from '../components/WordSwipe.vue';
import LetterPop from  '../components/LetterPop.vue';
import Slider from '../components/Slider.vue';
import Countdown from '../components/Countdown.vue';
import WishPhotoInput from '../components/WishPhotoInput.vue';

const store = useStore();

//#region RSVP Section
const showRSVP = ref(false);
const rsvpName = ref('');
const rsvpPhone = ref('');
const rsvpHeadCount = ref(2);
const rsvpNameDisabled = ref(false);
const RSVPError = ref('');
const RSVPSuccess = ref('');
const rsvpLoading = ref(false);

const rsvpClicked = async () => {
  showRSVP.value = true;
  RSVPError.value = '';
  RSVPSuccess.value = '';

  rsvpName.value = '';
  rsvpPhone.value = '';
  rsvpHeadCount.value = 2;
}
const uppercaseRSVPName = async () => {
  rsvpName.value = rsvpName.value.toUpperCase();
}
const rsvpSubmit = async () => {
  RSVPError.value = '';

  if (!rsvpName.value) {
    RSVPError.value = 'Sila masukkan nama anda untuk RSVP';
  } else {
    rsvpLoading.value = true;
    rsvpNameDisabled.value = true;
    
    let rsvpResult = await api('/RSVP/SaveRSVP', { name: rsvpName.value, phone: rsvpPhone.value, headCount: rsvpHeadCount.value }); // Live API calling
    // let rsvpResult = await simulateAPI(2000, false); // Simulating api error
    // let rsvpResult = await simulateAPI(2000, true); // Simulating api success
    rsvpLoading.value = false;

    rsvpNameDisabled.value = false;
    if (rsvpResult) {
      rsvpName.value = '';
      rsvpPhone.value = '';
      rsvpHeadCount.value = 2;
      RSVPSuccess.value = 'Terima Kasih kerana telah RSVP!';

      setTimeout(() => {
        showRSVP.value = false;
      }, 1500);
    } else {
      RSVPError.value = 'Maaf, RSVP sedang menghadapi masalah. Sila cuba lagi.';
    }
  }
}
//#endregion

//#region Wish Section
const showWishes = ref(false);
const showAddWish = ref(false);
const fontListing = ref([
  { "name": "Delius", "value": "'Delius', cursive" },
  { "name": "Gloria Hallelujah", "value": "'Gloria Hallelujah', cursive" },
  { "name": "Great Vibes", "value": "'Great Vibes', cursive" },
  { "name": "Indie Flower", "value": "'Indie Flower', cursive" },
  { "name": "Love Ya Like A Sister", "value": "'Love Ya Like A Sister', cursive" },
  { "name": "Macondo", "value": "'Macondo', cursive" },
  { "name": "Marck Script", "value": "'Marck Script', cursive" },
  { "name": "Petit Formal Script", "value": "'Petit Formal Script', cursive" },
  { "name": "Pinyon Script", "value": "'Pinyon Script', cursive" },
  { "name": "Playball", "value": "'Playball', cursive" },
  { "name": "Sacramento", "value": "'Sacramento', cursive" },
  { "name": "Satisfy", "value": "'Satisfy', cursive" },
  { "name": "Shadows Into Light", "value": "'Shadows Into Light', cursive" },
  { "name": "Zeyada", "value": "'Zeyada', cursive" }
])
const wishListing = ref([]);
const loadingWishes = ref(false);
const wishMessage =  ref('');
const wishSender = ref('');
const selectedFont = ref('');
const wishPhoto = ref(null);
const submittingWish = ref(false);
const wishError = ref('');
const wishSuccess = ref('');

const wishesClicked = async () => {
  showWishes.value = true;

  loadingWishes.value = true;
  wishListing.value = await api('/Wishes/GetWishes'); // Live get wish
  // wishListing.value = await simulateAPI(2000, null); // Simulate getting null for wishes list
  // wishListing.value = await simulateAPI(2000, []); // Simulate getting empty array for wishes list
  // wishListing.value = await simulateAPI(2000, [
  //   { id: 1, message: 'msg1', sender: 'bob1', time: '2023-11-10T12:12:12', font: fontListing.value[0].value },
  //   { id: 2, message: 'msg2 longer text for testing longer text for testing longer text for testing longer text for testing', sender: 'bob2', time: '2023-11-10T12:12:13', font: fontListing.value[1].value },
  //   { id: 16, message: 'msg3 longer text for testing', sender: 'bob3', time: '2023-11-10T12:12:14', font: fontListing.value[2].value, photoid: '3' },
  //   { id: 4, message: 'msg4 long long text here to see how it looks like ya msg4 long long text here to see how it looks like ya msg4 long long text here to see how it looks like ya msg4 long long text here to see how it looks like ya', sender: 'bob4', time: '2023-11-10T12:12:15', font: fontListing.value[3].value },
  //   { id: 5, message: 'msg5', sender: 'bob5', time: '2023-11-10T12:12:16', font: fontListing.value[4].value },
  // ]); // Simulate getting a list of wishes list

  wishListing.value.forEach(w => w.deleteWish = false);
  wishListing.value.forEach(w => w.deletingWish = false);
  loadingWishes.value = false;
}
const submitWish = async () => {
  wishError.value = '';
  if (!wishMessage.value || !wishSender.value) {
    wishError.value = 'Ucapan dan Dari tak boleh kosong';
    return;
  }

  submittingWish.value = true;
  let fd = new FormData();
  fd.append('message', wishMessage.value);
  fd.append('sender', wishSender.value);
  fd.append('font', `'Delius', cursive`);
  if (wishPhoto.value) {
    let photoObj = await resize(wishPhoto.value, false);
    let thumbnailObj = await resize(wishPhoto.value, true);
    fd.append('photo', photoObj.base64);
    fd.append('thumbnail', thumbnailObj.base64);
    fd.append('photoName', photoObj.name);
    fd.append('photoType', photoObj.type);
  }
  let sendWish = await api('/Wishes/SaveWish', fd); // Live api call to submit wish
  // let sendWish = await simulateAPI(2000, null); // Simulate null
  // let sendWish = await simulateAPI(2000, false); // Simulate false
  // let sendWish = await simulateAPI(2000, true); // Simulate true
  submittingWish.value = false;
  
  if (sendWish) {
    wishMessage.value = '';
    wishSender.value = '';
    wishPhoto.value = null;

    wishSuccess.value = 'Terima kasih kerana telah memberikan ucapan anda!';

    setTimeout(() => {
      showAddWish.value = false;
      wishesClicked();
    }, 1500);
  } else {
    wishError.value = 'Maaf, ucapan sedang menghadapi masalah. Sila cuba lagi.';
  }
}
const deleteWishClicked = async (wishObj) => {
  if (store.state.isReena) {
    wishListing.value.forEach(w => w.deleteWish = false);
    wishObj.deleteWish = true;
  }
}
const wishDeleteBackgroundClicked = (wishObj) => {
  wishObj.deleteWish = false;
}
const wishConfirmDelete = async (wishObj) => {
  wishObj.deletingWish = true;
  let deleteSuccess = await api('/Wishes/DeleteWish', wishObj); // For Live
  // let deleteSuccess = await simulateAPI(2000, null); // Simulating null
  // let deleteSuccess = await simulateAPI(2000, true); // Simulating true
  // let deleteSuccess = await simulateAPI(2000, false); // Simulating false
  wishObj.deletingWish = false;
  wishObj.deletingWish = false;
  wishObj.deleteWish = false;

  if (deleteSuccess) {
    let index = wishListing.value.findIndex(w => w.id == wishObj.id);
    if (index >= 0) {
      wishListing.value.splice(index, 1);
    }
  }
}
const openPhoto = (id) => {
  store.state.wishPhotoSource = `/Wishes/GetPhoto?id=${id}`;
  store.state.showWishPhoto = true;
}
const resize = (item, thumb) => {
  let res;
  let prom = new Promise(r => res = r);

  var resize_width = thumb ? 50 : 1000;

  var reader = new FileReader();
  reader.readAsDataURL(item);
  reader.name = item.name;//get the image's name
  reader.size = item.size; //get the image's size
  reader.onload = (event) => {
    var img = new Image();//create a image
    img.src = event.target.result;//result is base64-encoded Data URI
    img.name = event.target.name;//set name (optional)
    img.size = event.target.size;//set size (optional)

    let oriMineType = event.target.result.split(',')[0].match(/:(.*?);/)[1];
    
    img.onload = (el) => {
      var elem = document.createElement('canvas');//create a canvas

      if (img.size > 1000000 || thumb) {
        var scaleFactor = resize_width / el.target.width;
        elem.width = resize_width;
        elem.height = el.target.height * scaleFactor;
      } else {
        elem.width = el.target.width * 0.75;
        elem.height = el.target.height * 0.75;
      }

      var ctx = elem.getContext('2d');
      ctx.drawImage(el.target, 0, 0, elem.width, elem.height);
      var srcEncoded = ctx.canvas.toDataURL(oriMineType, 1);

      // var arr = srcEncoded.split(',');
      // var mime = arr[0].match(/:(.*?);/)[1];
      // var bstr = atob(arr[arr.length - 1]);
      // var n = bstr.length
      // var u8arr = new Uint8Array(n);
      // while(n--){
      //   u8arr[n] = bstr.charCodeAt(n);
      // }
      // let file = new File([u8arr], item.name, {type:mime});
      // console.log(file);
      // res(file);

      res({
        base64: srcEncoded.split(',')[1],
        name: event.target.name,
        type: oriMineType
      });
    }
  }

  return prom;
}

watch(() => showAddWish.value, (newVal) => {
  if (!newVal) {
    wishError.value = '';
    wishSuccess.value = '';
    wishPhoto.value = '';
    wishSender.value = '';
    wishMessage.value = '';
  }
})
//#endregion

const majlisStarted = ref(false);
const majlisEnded = ref(false);
const majlisStartDate = ref(new Date(2023,11,24,11,0));
const majlisEndDate = ref(new Date(2023,11,24,16,0));
const scrollTop = ref(0);

onMounted(() => {
  window.addEventListener("scroll", () => {
    scrollTop.value = window.pageYOffset;
  });

  getMajlisStartDate();
  getMajlisEndDate();
});

const getMajlisStartDate = () => {
  api('/Settings/GetSettings', { setting: 'majlisstart' }) // For live
  // simulateAPI(2000, null) // Simulate null
  // simulateAPI(2000, { value: '' }) // Simulate empty value
  // simulateAPI(2000, { value: '2023-11-24 01:00:50' }) // Simulate fake value
    .then(r => {
      if (r) {
        if (r.value) {
          majlisStartDate.value = new Date(r.value);
        } else {
          majlisStartDate.value = new Date('2023-12-24 11:00');
        }
      } else {
        majlisStartDate.value = new Date('2023-12-24 11:00');
      }
    });
}
const getMajlisEndDate = () => {
  api('/Settings/GetSettings', { setting: 'majlisend' }) // For live
  // simulateAPI(2000, null) // Simulate null
  // simulateAPI(2000, { value: '' }) // Simulate empty value
  // simulateAPI(2000, { value: '2023-11-24 01:10:00' }) // Simulate fake value
    .then(r => {
      if (r) {
        if (r.value) {
          majlisEndDate.value = r.value;
        } else {
          majlisEndDate.value = '2023-12-24 16:00';
        }
      } else {
        majlisEndDate.value = '2023-12-24 16:00';
      }
    });
}

watch(() => showWishes.value, (val) => {
  store.state.wishOrRSVPShow = val;
});
watch(() => showRSVP.value, (val) => {
  store.state.wishOrRSVPShow = val;
});
</script>

<style scoped>
.home-main {
  width: 100%;
  max-width: var(--maxwidth);
}
.content {
  transition: var(--mainTrans);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: var(--mainPadding);
  padding-bottom: 50px;
  text-align: center;
  color: rgb(109,58,9);
}
.walimatul {
  font-size: 1em;
  margin-bottom: 1.5em;
}
.main-name {
  font-size: 3.5em;
}
.main-rr {
  font-family: 'Allura', cursive;
  display: flex;
  width: 100%;
  text-align: center;
  justify-content: center;
  align-items: center;
}
.main-rr img {
  width: 75%;
}
.bismillah {
  width: 80%;
  max-width: 400px;
  margin-top: 3em;
  object-fit: contain;
}
.maps img {
  height: 3em;
  margin: 5px 0.5em;
  cursor: pointer;
}

.button-section {
  display: flex;
  margin-top: 3em;
}
.button-section button {
  background-color: transparent;
  backdrop-filter: blur(2px);
  margin: 0 2em;
  border-radius: 10px;
  border: 1px solid gray;
  box-shadow: 5px 5px 5px gray;
  cursor: pointer;
  transition: 0.3s;
}
.button-section button:active {
  transform: scale(0.95);
  box-shadow: none;
}
.button-section button img {
  width: 3em;
  pointer-events: none;
}

.date-container {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  font-size: 1.3em;
}
.date-row {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 5px;
}
.date-row > :nth-child(2) {
  margin: 0 20px;
  font-size: 2.5em;
}
.date-row  > :nth-child(1), .date-row > :nth-child(3) {
  width: 100%;
  border-top: 2px solid  rgb(109,58,9);
  border-bottom: 2px solid  rgb(109,58,9);
  padding: 10px 0;
}

.overlay-page {
  position: fixed;
  top: 0;
  background-color: rgba(255,255,255,0.75);
  backdrop-filter: blur(5px);
  z-index: 11;
  height: 100%;
  min-height: 100vh;
  max-height: -webkit-fill-available;
  width: 100%;
  max-width: var(--maxwidth);
  padding: 0 20%;
  padding-top: 170px;
  display: flex;
  flex-direction: column;
  align-items: center;
  box-sizing: border-box;
}
.overlay-page .close-rsvp {
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
  border: 1px solid gray;
  border-radius: 10px;
  background-color: transparent;
  position: absolute;
  font-size: 0.5em;
  position: absolute;
  bottom: 10%;
  left: calc(50% - 2.5em);
  min-width: 5em;
  min-width: 5em;
  max-height: 5em;
  max-height: 5em;
}
.close-rsvp img {
  width: 2em;
}

.wish-page {
  align-items: flex-start;
}
.wish-page * {
  margin-bottom: 0;
}
.wish-page > :nth-child(odd) {
  margin-bottom: 0.5em;
}
.wish-page select {
  width: 50%;
}
.wish-page textarea {
  resize: none;
  min-height: 7em;
}

.all-wishes-container {
  width: 100%;
  overflow: auto;
  margin-top: 0.5em;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: calc(100% - 170px);
}
.all-wishes-container .wish-container {
  border: 1px solid gray;
  margin: 5px 5px 0 5px;
  padding: 5px;
  width: calc(100% - 10px);
  box-sizing: border-box;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.wish-container > :nth-child(1) {
  white-space: pre-wrap;
  text-align: justify;
  padding-right: 20px;
}
.wish-container > :nth-child(2) {
  height: 30px;
  width: fit-content;
  border: 1px solid gray;
  margin: 5px 0;
  padding: 5px;
  cursor: pointer;
}
.wish-container > :nth-child(3) {
  display: flex;
  justify-content: flex-end;
  text-align: right;
}
.wish-container > :nth-child(4) {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background-color: rgba(128,128,128,0.3);
  z-index: 9;
  display: flex;
  align-items: center;
  justify-content: center;
}
.wish-container > :nth-child(4) > img {
  height: 50px;
  object-fit: contain;
  position: absolute;
  z-index: 6;
}
.wish-container > :nth-child(4) > div {
  height: 100%;
  width: 100%;
  z-index: 5;
}

.timing-table {
  border-collapse: collapse;
  width: 100%;
  margin-top: 1em;
}
td {
  padding: 3px 0;
  font-size: 0.9em;
}
td:nth-child(1) {
  width: 40%;
}
td:nth-child(3) {
  text-align: left;
  padding-left: 10px;
  width: 100%;
}
.vertical-line {
  border-left: 2px solid rgb(109,58,9);
}
.horizontal-line {
  height: 2px;
  width: 15px;
  background-color: rgb(109,58,9);
}
a {
  color: rgb(181, 95, 15);
}

.rsvptrans-enter-active, .rsvptrans-leave-active {
  transition: 0.5s ease-out;
}
.rsvptrans-enter-from, .rsvptrans-leave-to {
  transform: translateX(-100%);
}
.wishtrans-enter-active, .wishtrans-leave-active {
  transition: 0.5s ease-out;
}
.wishtrans-enter-from, .wishtrans-leave-to {
  transform: translateX(100%);
}
</style>